@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
.text{
    color: #fff;
    font-family: 'Montserrat', sans-serif;
}

.timer{
    margin-top: 40vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;

}

#second{
    color:#fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
}

#minute{
    color:#fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
}

#hour{
    color:#fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
}

#day{
    color:#fff;
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
}

