@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@600&display=swap");

.wrapper {
  background-color: #1a2027;
}

.backImg{
	height: 98.5vh;
	width: 48vw;
}

.glass {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  height: 99.5vh;
  width: 50vw;
  background: rgba(255, 255, 255, 0);
  /* background: black; */
  /* -webkit-backdrop-filter: blur(10px); */
  backdrop-filter: blur(5px);
}

.myText{
	font-family: 'Space Grotesk', sans-serif;
  color:'#fff';
  letterSpacing:'1px';
  fontSize:'2.5rem';
}

.live {
  display: flex;
  gap: 10px;
  justify-content: center;
}
