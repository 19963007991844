.timer {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  display: inline-block;
  padding: 1px 10px;
  text-align: center;
  position: absolute;
  top: 0;
  right: 0;
}

.col_4 {
  width: 50%;
  float: left;
}

.box {
  border-right: solid 1px rgba(255, 255, 255, 0.2);
  font-weight: 300;
  padding: 10px;
}

.col_4:last-child .box {
  border-right-color: transparent;
}

.box p {
  font-size: calc(16px + 2vmin);
  margin: 0;
}

.text {
  font-size: 14px;
}
